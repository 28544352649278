import signInActions from './actions'

const signInStatus = (state = { loading: false, status: { hasWarning: false, hasError: false }, regnumber: null }, action) => {
  switch (action.type) {
    case signInActions.signInPending:
      return { ...state, loading: action.pending }
    case signInActions.setSignIn:
      return { ...state, status: action.status }
    case signInActions.resetSignIn:
      return { loading: false, status: { hasWarning: false, hasError: false } }
    case signInActions.setVehicleRegistration:
      return {...state, regnumber: action.regnumber }
    default:
      return state
  }
}

export default signInStatus
