/* eslint-disable no-lone-blocks */
import React from 'react'
import {
  Switch,
  Route
} from 'react-router-dom'

import { Container } from '@jsluna/react'
import { Callback } from 'react-oidc'
import { ConnectedRouter } from 'connected-react-router'
import RegistrationForm from './components/Registration'
import SupplierSelection from './components/Supplier'
import TaskSelection from './components/Task'
import TaskTimeSelection from './components/TaskTime/'
import TermsAndConditions from './components/TermsAndConditions'
import UsernamePassword from './components/Username'
import Header from './components/Header'
import LoginForm from './components/Login'
import SignIn from './components/SignIn'
import MultipleSites from './components/MultipleSites'
import SignOut from './components/SignOut'
import { setToken } from './utils/storage'
import userManager from './utils/identity'
import Menu from './components/Menu'
import AssessmentStatements from './components/AssessmentStatements'
import ErrorPage from './components/ErrorPage'
import FirePolicy from './components/FirePolicy'
import WorkOrders from './components/WorkOrders/WorkOrdersList'
import LoginCallback from './components/LoginCallback'
import WorkOrderHomePage from './components/WorkOrderHomePage'
import Passes from './components/Passes/PassesList'
import PassQuestions from './components/Passes/PassQuestions'
import EmailVerification from './components/Email/EmailVerification'
import ForgottenPassword from './components/PasswordReset/ForgottenPassword'
import ResetPassword from './components/PasswordReset/ResetPassword'
import PasswordChangedSuccess from './components/PasswordReset/PasswordChangedSuccess'
import PasswordChangedError from './components/PasswordReset/PasswordChangedError'
import TokenExpired from './components/PasswordReset/TokenExpired'
import TokenUsed from './components/PasswordReset/TokenUsed'
import * as store from './store'
import MerchandiserSignIn from './components/MerchandiserSignIn'
import ChangePassword from './components/ChangePassword'
import ConfirmationPage from './components/ConfirmationPage'

class App extends React.Component {
  componentDidMount = () => {
    window.onunhandledrejection = (err) => {
      window.location.href = '/error'
    }
    // native application needs access to the app as sometimes it needs to refresh the page
    window.signinbookapp = this
  }

  render() {
    return (
      <ConnectedRouter history={store.history}>
        <Switch>
          <Route exact path="/confirmEmail" component={EmailVerification} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/resetpassword/expired" component={TokenExpired} />
          <Route exact path="/resetpassword/used" component={TokenUsed} />
          <Route exact path="/resetsuccess" component={PasswordChangedSuccess} />
          <Route exact path="/reseterror" component={PasswordChangedError} />
          <Route
            component={({ match }) => (
            <div id="outer-container">
              <Menu pageWrapId="page-wrap" outerContainerId="outer-container" />
              <div id="page-wrap" className="c-main-content">
                <Container>
                  <Header />
                  <Switch>
                    <Route exact path="/" component={LoginForm}/>
                    <Route exact path="/register" component={TermsAndConditions} />
                    <Route exact path="/register/details" component={RegistrationForm} />
                    <Route exact path="/register/username" component={UsernamePassword} />
                    <Route exact path="/login" component={LoginForm} />
                    <Route exact path="/supplier" component={SupplierSelection} />
                    <Route exact path="/task" component={TaskSelection} />
                    <Route exact path="/task/time" component={TaskTimeSelection} />
                    <Route exact path="/SignIn" component={SignIn} />
                    <Route exact path="/MultipleSites" component={MultipleSites} />
                    <Route exact path="/firepolicy" component={FirePolicy} />
                    <Route exact path="/workorders/:withbackbutton" component={WorkOrders} />
                    <Route exact path="/LoggedIn" component={LoginCallback} />
                    <Route exact path="/passes" component={Passes} />
                    <Route exact path="/passquestions" component={PassQuestions} />
                    <Route exact path="/forgottenpassword" component={ForgottenPassword} />
                    <Route exact path="/changepassword" component={ChangePassword} />
                    <Route
                      exact
                      path="/SignInCallback"
                      render={routeProps => (
                        <Callback
                          onSuccess={(user) => {
                            setToken(user.access_token, user.expires_at)
                            routeProps.history.push('/LoggedIn')
                          }}
                          onError={(error) => {
                            console.log(error)
                            routeProps.history.push('/Error')
                          }}
                          userManager={userManager}
                        />
                      )}
                    />
                    <Route exact path="/WorkOrderHome" component={WorkOrderHomePage} />
                    <Route exact path="/AssessmentStatements" component={AssessmentStatements} />
                    <Route exact path="/SignOut" component={SignOut} />
                    <Route exact path="/MerchandiserSignIn" component={MerchandiserSignIn} />
                    <Route exact path="/LoggedOut" render= {(props) => <LoginForm {...props} loggedOut />} />
                    <Route exact path="/confirmation" component={ConfirmationPage} />
                    <Route exact path="/error" component={ErrorPage} />
                    <Route path="*" component={ErrorPage} />
                  </Switch>
                </Container>
              </div>
            </div>
          )}
          />
        </Switch>
      </ConnectedRouter>
    )
  }
}

export default App
