 const signInActions = {
  signInPending: 'LOADING::SIGNIN',
  signInRequest: 'REQUEST::SIGNIN',
  setSignIn: 'SET::SIGNIN',
  resetSignIn: 'RESET::SIGNIN',
  setVehicleRegistration: 'SET::CARREGISTRATION'
}

export const setSignInState = status => ({ type: signInActions.setSignIn, status })
export const setSignInPending = pending => ({ type: signInActions.signInPending, pending })
export const requestSignIn = (latitude, longitude) => ({ type: signInActions.signInRequest, latitude, longitude })
export const resetSignIn = () => ({ type: signInActions.resetSignIn })
export const setCarRegistration = regnumber => ({ type: signInActions.setVehicleRegistration, regnumber })

export default signInActions
